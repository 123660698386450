import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LacertaCmsNgrxFacade } from '../+state/cms.facade';
import { LacertaDynamicWagtailPage } from '../page.model';

@Component({
  selector: 'lacerta-dynamic-page',
  template: `
    <section *ngIf="fullPage$ | async as fullPage" [id]="fullPage.meta.slug">
      <lacerta-stream-field [blocks]="(fullPage$ | async)?.body"></lacerta-stream-field>
    </section>
  `,
})
export class LacertaDynamicPageComponent {
  fullPage$: Observable<LacertaDynamicWagtailPage> = this.lacertaCmsNgrxFacade.currentDetailPage$.pipe(
    map((page) => page as LacertaDynamicWagtailPage)
  );
  constructor(private lacertaCmsNgrxFacade: LacertaCmsNgrxFacade) {}
}
