import { Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: '[lacertaVideoAutoresize]',
})
export class VideoAutoresizeDirective implements OnInit {
  constructor(private element: ElementRef) {}

  ngOnInit(): void {
    const iframes: HTMLIFrameElement[] = [
      ...this.element.nativeElement.querySelectorAll('iframe[src*="youtube.com"],iframe[src*="vimeo.com"]'),
    ];
    if (iframes) {
      iframes.forEach((iframe) => {
        iframe.style.position = 'absolute';
        iframe.style.top = '0';
        iframe.style.left = '0';
        iframe.style.width = '100%';
        iframe.style.height = '100%';
        iframe.style.border = '0';
        const parentElement = iframe.parentElement as HTMLDivElement;
        parentElement.style.position = 'relative';
        parentElement.style.width = '100%';
        parentElement.style.paddingBottom = '56.25%';
      });
    }
  }
}
