export enum SetisPageType {
  root = 'setis.SetisHome',
  product = 'setis.SetisProduct',
  home = 'setis.SetisHome',
  page = 'setis.SetisPage',
  products = 'setis.SetisProductIndexPage',
  productType = 'setis.SetisProductTypePage',
  pageWithSubPages = 'setis.SetisPageWithSubPages',
  form = 'setis.SetisFormPage',
}
