<div class="green-banner has-background-link has-text-white is-centered">
  <div class="container">
    <div class="columns is-gapless">
      <div class="column">
        <div class="content">
          <lacerta-rich-text-block
            *ngIf="leftContent"
            class="leftContent"
            [content]="leftContent | linkToButton: 'button is-primary is-size-5'"
          ></lacerta-rich-text-block>
        </div>
      </div>
      <div class="column bg-pattern">
        <div class="content"><lacerta-rich-text-block class="rightContent" [content]="rightContent"></lacerta-rich-text-block></div>
      </div>
    </div>
  </div>
</div>
