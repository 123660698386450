import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { SetisWagtailModule } from './setis-wagtail/setis-wagtail.module';
import { RouterModule } from '@angular/router';
import { LacertaCoreModule } from '@lacerta/core';
import { LacertaWagtailComponentTypeMapping } from '@lacerta/cms';
import { LacertaMetaConfig, mapImageToMetaValues } from '@lacerta/meta';
import { SetisWagtailParagraphComponent } from './setis-wagtail/block/paragraph.component';
import { SetisWagtailImageWithTextComponent } from './setis-wagtail/block/image-with-text.component';
import { SetisWagtailTwoColumnsComponent } from './setis-wagtail/block/two-columns.component';
import { SetisWagtailQuoteComponent } from './setis-wagtail/block/quote.component';
import { SetisWagtailTwoColumnsGreenComponent } from './setis-wagtail/block/two-columns-green.component';
import { SetisWagtailImageWithArrowTextComponent } from './setis-wagtail/block/image-with-arrow-text.component';
import { SetisWagtailParagraphGreenComponent } from './setis-wagtail/block/paragraph-green.component';
import { SetisWagtailGalleryComponent } from './setis-wagtail/block/gallery.component';
import { SetisWagtailTableComponent } from './setis-wagtail/block/table.component';
import { SetisWagtailWhitespaceComponent } from './setis-wagtail/block/whitespace.component';
import { SetisWagtailProductTypePageComponent } from './setis-wagtail/product/product-type.component';
import { SetisWagtailProductPageComponent } from './setis-wagtail/product/product.component';
import { SetisWagtailContactPageComponent } from './setis-wagtail/contact-form/contact-page.component';
import { SetisPageType } from './setis-wagtail/page/page.model';
import { SetisWagtailPageComponent } from './setis-wagtail/page/setis-page.component';
import { environment } from '../../environments/environment';
import { SetisProductPage } from './setis-wagtail/product/product.model';
import { SetisBlockType } from './setis-wagtail/block/block.model';
import { preventReimport } from '@lacerta/util';
import { BulmaFormControlComponent } from '@lacerta/bulma';

const wagtailComponentTypeMapping: LacertaWagtailComponentTypeMapping = [
  [SetisBlockType.paragraph, SetisWagtailParagraphComponent],
  [SetisBlockType.image_with_text, SetisWagtailImageWithTextComponent],
  [SetisBlockType.two_columns, SetisWagtailTwoColumnsComponent],
  [SetisBlockType.quote, SetisWagtailQuoteComponent],
  [SetisBlockType.two_columns_green, SetisWagtailTwoColumnsGreenComponent],
  [SetisBlockType.image_with_arrow_text, SetisWagtailImageWithArrowTextComponent],
  [SetisBlockType.paragraph_green, SetisWagtailParagraphGreenComponent],
  [SetisBlockType.gallery, SetisWagtailGalleryComponent],
  [SetisBlockType.table, SetisWagtailTableComponent],
  [SetisBlockType.whitespace, SetisWagtailWhitespaceComponent],
  [SetisPageType.page, SetisWagtailPageComponent],
  [SetisPageType.pageWithSubPages, SetisWagtailPageComponent],
  [SetisPageType.products, SetisWagtailPageComponent],
  [SetisPageType.productType, SetisWagtailProductTypePageComponent],
  [SetisPageType.product, SetisWagtailProductPageComponent],
  [SetisPageType.form, SetisWagtailContactPageComponent],
];

export const setisProductMetaMapping = (page: SetisProductPage) => ({
  ...(page.image && mapImageToMetaValues(page.image.meta.download_url, page.image.width, page.image.height)),
});

const metaConfig: LacertaMetaConfig<SetisProductPage> = {
  customPageMetaMappings: [[SetisPageType.product, setisProductMetaMapping]],
};

@NgModule({
  imports: [
    CommonModule,
    SetisWagtailModule,
    LacertaCoreModule.forRoot({
      wagtailComponentTypeMapping,
      metaConfig,
      rootPageType: SetisPageType.root,
      menuParentPageType: SetisPageType.root,
      environment,
      formControlComponent: BulmaFormControlComponent,
    }),
  ],
  declarations: [FooterComponent],
  exports: [FooterComponent, RouterModule],
})
export class SetisCoreModule {
  constructor(@Optional() @SkipSelf() parentModule: SetisCoreModule) {
    preventReimport(parentModule, 'SetisCoreModule');
  }
}
