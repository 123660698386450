<section>
  <div class="container has-text-centered-mobile">
    <div class="columns">
      <div class="column">
        <setis-content-header [title]="product?.title" [subtitle]="(currentDetailPage$ | async)?.price?.toString()"></setis-content-header>
      </div>
      <div class="column is-narrow has-vertically-aligned-content"><a class="has-text-link" routerLink="../">< Back</a></div>
    </div>
    <div class="columns">
      <div class="column is-one-third">
        <div class="columns is-multiline">
          <ng-container *ngFor="let image of galleryImages$ | async as galleryImages; let i = index">
            <div class="column is-narrow">
              <lacerta-gallery-image [images]="galleryImages" [imageIndex]="i"></lacerta-gallery-image>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="column is-two-thirds">
        <div class="content">
          <p>Code: {{ (currentDetailPage$ | async)?.code }}</p>
          <p>{{ (currentDetailPage$ | async)?.description }}</p>
          <lacerta-dynamic-page></lacerta-dynamic-page>
        </div>
      </div>
    </div>
  </div>
</section>
