import { Component } from '@angular/core';
import { LacertaDynamicTypeComponent } from '@lacerta/cms';

@Component({
  template: `
    <setis-paragraph-green>
      <lacerta-rich-text-block *ngIf="context" [content]="context | linkToButton: 'button is-link is-size-5'"></lacerta-rich-text-block>
    </setis-paragraph-green>
  `,
})
export class SetisWagtailParagraphGreenComponent extends LacertaDynamicTypeComponent<string> {}
