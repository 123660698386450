import { Component } from '@angular/core';
import { LacertaDynamicTypeComponent } from '@lacerta/cms';
import { BlockUtil } from './block-util';
import { VervitImageWithTextBlock } from '@lacerta/vervit/feature-shell';

@Component({
  template: `
    <lacerta-full-width-image [image]="image">
      <setis-title-pointer [title]="this.context?.text"></setis-title-pointer>
    </lacerta-full-width-image>
  `,
})
export class SetisWagtailImageWithArrowTextComponent extends LacertaDynamicTypeComponent<VervitImageWithTextBlock> {
  get image() {
    return this.context?.image.original_image
      ? BlockUtil.mapImageWithTextBlockToFullWidthImage(this.context?.image.original_image)
      : undefined;
  }
}
