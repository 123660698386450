import { Component } from '@angular/core';
import { LacertaFullWidthImage } from '@lacerta/ui';

@Component({
  selector: 'setis-paragraph-green',
  styleUrls: ['./paragraph-green.component.scss'],
  templateUrl: './paragraph-green.component.html',
})
export class ParagraphGreenComponent {
  get backgroundImage(): LacertaFullWidthImage {
    return {
      height: 300,
      width: 1900,
      url: '../../../../assets/images/bg-pattern-2.png',
    };
  }
}
