import { Directive, HostListener } from '@angular/core';
import { Router } from '@angular/router';

const buildRouterLink = (href: string): string | undefined => {
  if (href.startsWith('/documents/')) {
    return undefined;
  } else if (href.startsWith('/')) {
    return href;
  } else if (href.startsWith(document.location.origin)) {
    return href.replace(document.location.origin, '');
  } else {
    return undefined;
  }
};

@Directive({
  selector: '[lacertaRouteTransformer]',
})
export class RouteTransformerDirective {
  constructor(private router: Router) {}

  @HostListener('click', ['$event'])
  public onClick(event: Event) {
    const element = event.target as HTMLElement;
    const navigated = this.navigateWithElement(element);
    const parentElement = element?.parentElement;
    const navigatedParent = parentElement ? this.navigateWithElement(parentElement) : false;
    if (navigated || navigatedParent) {
      event.preventDefault();
    }
  }

  private navigateWithElement(element: HTMLElement) {
    if (element?.tagName === 'A') {
      const href = element?.getAttribute('href');
      const routerLink = href ? buildRouterLink(href) : undefined;
      if (routerLink) {
        this.router.navigate([routerLink]);
        return true;
      }
    }
    return false;
  }
}
