<div class="item">
  <div class="columns has-text-centered-mobile">
    <div class="column is-one-third is-flex">
      <a class="image-link" routerLink="{{ product?.meta?.slug }}">
        <img [src]="product?.thumbnail?.url" />
        <div *ngIf="product?.price" class="product-price">{{ product?.price }}</div>
      </a>
    </div>
    <div class="column is-two-third has-text-link">
      <div class="content">
        <p>Code: {{ product?.code }}</p>
        <p>{{ product?.title }}</p>
      </div>
      <div><a routerLink="{{ product?.meta?.slug }}" class="has-text-link">View product details ></a></div>
    </div>
  </div>
</div>
