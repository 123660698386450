import { Component, Input } from '@angular/core';
import { SetisHome } from '../setis-wagtail/home/home.model';

@Component({
  selector: 'setis-footer',
  template: `
    <footer class="footer">
      <div class="container">
        <div class="level">
          <div class="level-left">
            <div class="level-item">
              <a routerLink="/">
                <img *ngIf="page" [src]="page.logo_180.url" [width]="page.logo_180.width" />
              </a>
            </div>
          </div>
          <div class="level-right">
            <div class="level-item has-text-link is-size-4">
              <a href="https://www.vervit.be" target="_blank>">
                <img *ngIf="page" [src]="page.footer_40.url" [height]="page.footer_40.height" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  `,
})
export class FooterComponent {
  @Input()
  page?: SetisHome | null;
}
