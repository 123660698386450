import { Pipe, PipeTransform } from '@angular/core';
import { pathWithoutFragment } from '@lacerta/util';

const anchorLinkRegex = /a href="(#.+)"/g;

@Pipe({
  name: 'anchorLink',
})
export class AnchorLinkPipe implements PipeTransform {
  transform(value: string): string {
    let result = value;
    let matches;
    const location = pathWithoutFragment(window.location.href);
    while ((matches = anchorLinkRegex.exec(value))) {
      result = result.replace(matches[0], `a href="${location}${matches[1]}"`);
    }
    return result;
  }
}
