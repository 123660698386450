import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { ProductTypeComponent } from './product-type/product-type.component';
import { SetisProductDetailComponent } from './product-detail/product-detail.component';
import { ProductSummaryComponent } from './product-summary/product-summary.component';
import { LacertaWagtailModule } from '@lacerta/wagtail';
import { LacertaGalleryImageModule } from '@lacerta/ui';
import { LacertaDynamicModule } from '@lacerta/cms';

@NgModule({
  imports: [CommonModule, SharedModule, LacertaWagtailModule, LacertaGalleryImageModule, LacertaDynamicModule],
  declarations: [ProductTypeComponent, SetisProductDetailComponent, ProductSummaryComponent],
  exports: [ProductTypeComponent, SetisProductDetailComponent, ProductSummaryComponent],
})
export class ProductModule {}
