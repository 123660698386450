import { Component, Input } from '@angular/core';

@Component({
  selector: 'setis-content-header',
  templateUrl: './content-header.component.html',
  styleUrls: ['./content-header.component.scss'],
})
export class ContentHeaderComponent {
  @Input() title?: string;
  @Input() subtitle?: string;
}
