import { Component, Input } from '@angular/core';

@Component({
  selector: 'setis-title-pointer',
  templateUrl: './title-pointer.component.html',
  styleUrls: ['./title-pointer.component.scss'],
})
export class TitlePointerComponent {
  @Input() public title?: string;
}
