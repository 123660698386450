import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'slugRoute',
})
export class SlugRoutePipe implements PipeTransform {
  transform(value: string | null | undefined): string | undefined {
    return value ? `/${value}` : undefined;
  }
}
