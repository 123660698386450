import { Component, Input } from '@angular/core';
import { SetisProductPage } from '../../../core/setis-wagtail/product/product.model';

@Component({
  selector: 'setis-product-summary',
  templateUrl: './product-summary.component.html',
  styleUrls: ['./product-summary.component.scss'],
})
export class ProductSummaryComponent {
  @Input() product?: SetisProductPage;
}
