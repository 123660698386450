import { Component, Input } from '@angular/core';

@Component({
  selector: 'setis-text-brackets',
  templateUrl: './text-brackets.component.html',
  styleUrls: ['./text-brackets.component.scss'],
})
export class TextBracketsComponent {
  @Input() text?: string;
}
