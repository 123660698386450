import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { SetisWagtailParagraphComponent } from './block/paragraph.component';
import { SetisWagtailImageWithTextComponent } from './block/image-with-text.component';
import { SetisWagtailTwoColumnsComponent } from './block/two-columns.component';
import { SetisWagtailProductTypePageComponent } from './product/product-type.component';
import { ProductModule } from '../../features/product/product.module';
import { SetisWagtailProductPageComponent } from './product/product.component';
import { SetisWagtailContactPageComponent } from './contact-form/contact-page.component';
import { ContactModule } from '../../features/contact/contact.module';
import { SetisWagtailPageComponent } from './page/setis-page.component';
import { SetisWagtailQuoteComponent } from './block/quote.component';
import { SetisWagtailTwoColumnsGreenComponent } from './block/two-columns-green.component';
import { SetisWagtailImageWithArrowTextComponent } from './block/image-with-arrow-text.component';
import { SetisWagtailParagraphGreenComponent } from './block/paragraph-green.component';
import { SetisWagtailGalleryComponent } from './block/gallery.component';
import { SetisWagtailTableComponent } from './block/table.component';
import { SetisWagtailWhitespaceComponent } from './block/whitespace.component';
import { LacertaFullWidthImageModule } from '@lacerta/ui';
import { LacertaDynamicModule } from '@lacerta/cms';
import { preventReimport } from '@lacerta/util';

const lacertaDynamicTypeComponents = [
  SetisWagtailImageWithTextComponent,
  SetisWagtailParagraphComponent,
  SetisWagtailTwoColumnsComponent,
  SetisWagtailQuoteComponent,
  SetisWagtailPageComponent,
  SetisWagtailProductTypePageComponent,
  SetisWagtailProductPageComponent,
  SetisWagtailContactPageComponent,
  SetisWagtailTwoColumnsGreenComponent,
  SetisWagtailImageWithArrowTextComponent,
  SetisWagtailParagraphGreenComponent,
  SetisWagtailGalleryComponent,
  SetisWagtailTableComponent,
  SetisWagtailWhitespaceComponent,
];

const featureModules = [ProductModule, ContactModule];

@NgModule({
  imports: [SharedModule, CommonModule, featureModules, LacertaFullWidthImageModule, LacertaDynamicModule],
  declarations: [lacertaDynamicTypeComponents],
})
export class SetisWagtailModule {
  constructor(@Optional() @SkipSelf() parentModule: SetisWagtailModule) {
    preventReimport(parentModule, 'SetisWagtailModule');
  }
}
