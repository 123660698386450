/* eslint-disable @typescript-eslint/naming-convention */
import { WagtailImage, WagtailImageRenditionField, WagtailStreamField, WagtailStructBlock } from '@lacerta/wagtail';
import { LacertaWagtailPage } from '@lacerta/cms';

export interface ProductGalleryImage {
  id: number;
  meta: {
    type: string;
  };
  image: WagtailImage;
  caption: string;
  thumbnail: WagtailImageRenditionField;
  thumbnail_large: WagtailImageRenditionField;
}

export interface SetisProductPage extends LacertaWagtailPage {
  image: WagtailImage;
  thumbnail: WagtailImageRenditionField;
  gallery_images: ProductGalleryImage[];
  code: string;
  description: string;
  price: number;
  body: WagtailStreamField<WagtailStructBlock>[];
}

export enum SetisProductField {
  thumbnail = 'thumbnail',
  gallery_images = 'gallery_images',
  code = 'code',
  price = 'price',
  description = 'description',
}
