import { Component, Input } from '@angular/core';
import { LacertaCmsNgrxFacade, LacertaWagtailPage } from '@lacerta/cms';
import { filter, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { FormPage } from '../../core/setis-wagtail/contact-form/contact-form.model';
import { LacertaFormControlType, LacertaFormField, LacertaFormMessages, mapWagtailFormToLacertaForm } from '@lacerta/form';

@Component({
  selector: 'setis-contact-page',
  template: `<section>
    <div class="container">
      <lacerta-rich-text-block [content]="(currentDetailPage$ | async)?.intro"></lacerta-rich-text-block>
      <br />
      <br />
      <div class="columns">
        <div class="column is-4">
          <div class="content">
            <lacerta-rich-text-block [content]="(currentDetailPage$ | async)?.address"></lacerta-rich-text-block>
          </div>
        </div>
        <div class="column is-6 has-vertically-aligned-content">
          <div class="content has-text-weight-bold has-text-link is-medium">Contact us</div>
          <lacerta-form
            *ngIf="fields$ | async as fields"
            [fields]="fields"
            [target]="page?.meta?.slug"
            [formMessages]="formMessages"
          ></lacerta-form>
        </div>
      </div>
    </div>
  </section>`,
})
export class ContactComponent {
  @Input() page?: LacertaWagtailPage;

  currentDetailPage$: Observable<FormPage> = this.lacertaCmsNgrxFacade.currentDetailPage$.pipe(map((page) => page as FormPage));
  fields$: Observable<LacertaFormField<unknown>[]> = this.currentDetailPage$.pipe(
    filter((page) => !!page && !!page.form_fields),
    map((page) =>
      mapWagtailFormToLacertaForm(page.form_fields).concat(
        {
          label: 'Are you human?',
          controlType: LacertaFormControlType.captcha,
          required: true,
          key: 'g-recaptcha-response',
        },
        {
          value: 'Send',
          controlType: LacertaFormControlType.submit,
          style: 'is-primary is-size-5 has-text-white',
        }
      )
    )
  );
  formMessages: LacertaFormMessages = { errors: { required: 'This field is required.', email: 'Please enter a valid e-mail address.' } };

  constructor(private lacertaCmsNgrxFacade: LacertaCmsNgrxFacade) {}
}
