import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LacertaBulmaNavbarItem } from './navbar-items/navbar-items.component';

export interface LacertaBulmaNavbarBrand {
  imageUrl: string;
  width: number;
}

@Component({
  selector: 'lacerta-bulma-navbar',
  template: `<nav class="navbar is-fixed-top is-spaced" role="navigation" aria-label="main navigation">
    <div class="container">
      <div class="navbar-brand">
        <a id="logo" class="navbar-item" routerLink="/" *ngIf="brand"><img [src]="brand.imageUrl" [width]="brand.width" /></a>
        <a
          role="button"
          class="navbar-burger"
          aria-label="menu"
          aria-expanded="false"
          [ngClass]="{ 'is-active': menuActive }"
          (click)="menuActive = !menuActive"
        >
          <span aria-hidden="true"></span> <span aria-hidden="true"></span> <span aria-hidden="true"></span>
        </a>
      </div>
      <div class="navbar-menu" [ngClass]="{ 'is-active': menuActive }" (click)="menuActive = !menuActive">
        <lacerta-bulma-navbar-items
          [ngClass]="'navbar-end'"
          [bulmaNavbarItems]="navbarItems"
          [dropdownArrowless]="dropdownArrowless"
        ></lacerta-bulma-navbar-items>
      </div>
    </div>
  </nav>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LacertaBulmaNavbarComponent {
  @Input()
  navbarItems?: LacertaBulmaNavbarItem[] | null;
  @Input()
  brand?: LacertaBulmaNavbarBrand | null;
  @Input()
  dropdownArrowless?: boolean;

  menuActive = false;
}
