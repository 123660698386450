import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

export interface LacertaGalleryImage {
  thumbnail: string;
  original: string;
}

@Component({
  selector: 'lacerta-gallery-image',
  template: `
    <div>
      <figure class="image"><img class="cover" [src]="thumbnail" (click)="openModal()" /></figure>
      <div [ngClass]="{ 'is-active': modalOpen }" class="modal">
        <div class="modal-background" (click)="closeModal()">
          <div fxLayout="row" fxLayoutAlign="center center" fxFill>
            <a class="previous" (click)="previous(); $event.stopPropagation()" title="previous">&#8249;</a>
            <div fxFlex></div>
            <a class="next" (click)="next(); $event.stopPropagation()" title="next">&#8250;</a>
          </div>
        </div>
        <div class="modal-content">
          <figure
            class="image is-narrow cover"
            (click)="next()"
            [ngStyle]="{ 'background-image': 'url(' + currentOriginalImage + ')' }"
          ></figure>
        </div>
        <button class="modal-close is-large" aria-label="close" (click)="closeModal()"></button>
      </div>
    </div>
  `,
  styleUrls: ['./gallery-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GalleryImageComponent implements OnInit {
  @Input() images?: LacertaGalleryImage[];
  @Input() imageIndex?: number;
  currentIndex = 0;
  modalOpen = false;

  openModal() {
    this.modalOpen = true;
  }

  closeModal() {
    this.modalOpen = false;
  }

  next() {
    this.currentIndex = (this.currentIndex + 1) % this.imagesLength;
  }

  previous() {
    this.currentIndex = (this.currentIndex - 1 + this.imagesLength) % this.imagesLength;
  }

  ngOnInit(): void {
    this.currentIndex = this.safeImageIndex;
  }

  get imagesLength() {
    return this.images?.length ?? 0;
  }

  get currentOriginalImage() {
    return this.images?.[this.currentIndex]?.original;
  }

  get thumbnail() {
    return this.images?.[this.safeImageIndex].thumbnail;
  }

  get safeImageIndex() {
    return this.imageIndex ?? 0;
  }
}
