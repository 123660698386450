import { Component, Input } from '@angular/core';

@Component({
  selector: 'lacerta-rich-text-block',
  template: `<div
    *ngIf="content"
    [innerHtml]="content | backendLink | externalLinkBlank | anchorLink | sanitizeHtml"
    lacertaRouteTransformer
    lacertaVideoAutoresize
  ></div>`,
  styleUrls: ['./rich-text-block-component.component.scss'],
})
export class RichTextComponent {
  @Input() content?: string;
}
