import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { WagtailMenuPage } from '@lacerta/wagtail';
import { map } from 'rxjs/operators';
import { SetisHome } from './core/setis-wagtail/home/home.model';
import { LacertaBulmaNavbarBrand, LacertaBulmaNavbarItem } from '@lacerta/bulma';
import { LacertaCmsNgrxFacade } from '@lacerta/cms';
import { filterNullOrUndefined } from '@lacerta/util';

@Component({
  selector: 'setis-root',
  template: `
    <lacerta-bulma-navbar [navbarItems]="navbarItems$ | async" [brand]="brand$ | async" [dropdownArrowless]="true"></lacerta-bulma-navbar>
    <router-outlet></router-outlet>
    <setis-footer [page]="rootPage$ | async"></setis-footer>
  `,
})
export class SetisAppComponent {
  rootPage$ = this.lacertaCmsNgrxFacade.rootPage$.pipe(map((page) => page as SetisHome));

  brand$: Observable<LacertaBulmaNavbarBrand> = this.rootPage$.pipe(
    map((rootPage: SetisHome) => ({
      imageUrl: rootPage.logo_alt_180 ? rootPage.logo_alt_180.url : '',
      width: 180,
    }))
  );

  navbarItems$: Observable<LacertaBulmaNavbarItem[]> = this.lacertaCmsNgrxFacade.menu$.pipe(
    filterNullOrUndefined(),
    map((menu) => this.mapToLacertaBulmaNavbarItem(menu))
  );

  private mapToLacertaBulmaNavbarItem = (menu: WagtailMenuPage[]): LacertaBulmaNavbarItem[] =>
    menu.map((item) => ({
      text: item.page.title,
      targetSlug: item.page.meta.slug,
      children: item.children ? this.mapToLacertaBulmaNavbarItem(item.children) : [],
      exact: item.exact ?? false,
    }));

  constructor(private lacertaCmsNgrxFacade: LacertaCmsNgrxFacade) {}
}
