<ng-container *ngFor="let item of bulmaNavbarItems">
  <a
    *ngIf="!item.children.length"
    class="navbar-item"
    [routerLink]="parentPath + (item.targetSlug | slugRoute)"
    routerLinkActive="is-active"
    [routerLinkActiveOptions]="{ exact: item.exact }"
  >
    {{ item.text }}
  </a>
  <a *ngIf="item.children.length" class="navbar-item has-dropdown is-hoverable">
    <a
      class="navbar-link"
      [ngClass]="{ 'is-arrowless': dropdownArrowless }"
      [routerLink]="parentPath + (item.targetSlug | slugRoute)"
      routerLinkActive="is-active"
      [routerLinkActiveOptions]="{ exact: item.exact }"
    >
      {{ item.text }}
    </a>
    <div class="navbar-dropdown">
      <lacerta-bulma-navbar-items
        [bulmaNavbarItems]="item.children"
        [parentPath]="parentPath + (item.targetSlug | slugRoute)"
      ></lacerta-bulma-navbar-items>
    </div>
  </a>
</ng-container>
