import { WagtailFormField, WagtailFormFieldType } from '@lacerta/wagtail';
import { LacertaFormControlType, LacertaFormField } from './form.model';

const formFieldTypeMap: { [type in WagtailFormFieldType]: LacertaFormControlType } = {
  singleline: LacertaFormControlType.textbox,
  multiline: LacertaFormControlType.textarea,
  email: LacertaFormControlType.email,
  checkboxes: LacertaFormControlType.checkboxes,
  checkbox: LacertaFormControlType.checkbox,
  number: LacertaFormControlType.number,

  // TODO: implement these
  date: LacertaFormControlType.textbox,
  datetime: LacertaFormControlType.textbox,
  url: LacertaFormControlType.textbox,
  dropdown: LacertaFormControlType.textbox,
  multiselect: LacertaFormControlType.textbox,
  radio: LacertaFormControlType.textbox,
  hidden: LacertaFormControlType.textbox,
  singlefile: LacertaFormControlType.textbox,
  multifile: LacertaFormControlType.textbox,
};

const mapWagtailFormFieldToLacertaFormField: (wagtailFormField: WagtailFormField<unknown>) => LacertaFormField<unknown> = (
  wagtailFormField: WagtailFormField<unknown>
) => ({
  key: wagtailFormField.clean_name || wagtailFormField.label.toLowerCase(),
  label: wagtailFormField.label,
  placeholder: wagtailFormField.help_text,
  required: wagtailFormField.required,
  controlType: formFieldTypeMap[wagtailFormField.field_type],
});

export const mapWagtailFormToLacertaForm: (wagtailFormField: WagtailFormField<unknown>[]) => LacertaFormField<unknown>[] = (
  wagtailFormField: WagtailFormField<unknown>[]
) => wagtailFormField?.map(mapWagtailFormFieldToLacertaFormField);
