/* eslint-disable @typescript-eslint/naming-convention */
export enum SetisBlockType {
  paragraph = 'paragraph',
  image_with_text = 'image_with_text',
  two_columns = 'two_columns',
  quote = 'quote',
  two_columns_green = 'two_columns_green',
  image_with_arrow_text = 'image_with_arrow_text',
  paragraph_green = 'paragraph_green',
  gallery = 'gallery',
  table = 'table',
  whitespace = 'whitespace',
}
