import { NgModule } from '@angular/core';
import { ContentHeaderComponent } from './content-header/content-header.component';
import { ParagraphGreenComponent } from './paragraph-green/paragraph-green.component';
import { TextBracketsComponent } from './text-brackets/text-brackets.component';
import { TitlePointerComponent } from './title-pointer/title-pointer.component';
import { TitleTransparentBlockComponent } from './title-transparent-block/title-transparent-block.component';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { LacertaCmsModule } from '@lacerta/cms';
import { ParagraphComponent } from './paragraph/paragraph.component';
import { TwoColumnsComponent } from './two-columns/two-columns.component';
import { TwoColumnsGreenComponent } from './two-columns-green/two-columns-green.component';
import { GalleryComponent } from './gallery/gallery.component';
import { LacertaFullWidthImageModule, LacertaGalleryImageModule } from '@lacerta/ui';
import { FlexLayoutModule } from '@angular/flex-layout';

const sharedModules = [CommonModule, RouterModule, LacertaCmsModule];
const uiComponents = [
  ContentHeaderComponent,
  ParagraphGreenComponent,
  TextBracketsComponent,
  TitlePointerComponent,
  TitleTransparentBlockComponent,
  ParagraphComponent,
  TwoColumnsComponent,
  TwoColumnsGreenComponent,
  GalleryComponent,
];

@NgModule({
  imports: [sharedModules, LacertaGalleryImageModule, FlexLayoutModule, LacertaFullWidthImageModule],
  declarations: [uiComponents, GalleryComponent],
  exports: [sharedModules, uiComponents],
})
export class SharedModule {}
