import { Component, Input, OnInit } from '@angular/core';
import { LacertaImageChooserBlock } from '@lacerta/cms';
import { LacertaGalleryImage } from '@lacerta/ui';

@Component({
  selector: 'setis-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
})
export class GalleryComponent implements OnInit {
  @Input() images?: LacertaImageChooserBlock[];
  lacertaGalleryImages?: LacertaGalleryImage[];

  ngOnInit(): void {
    this.lacertaGalleryImages = this.images?.map((imageChooserBlock: LacertaImageChooserBlock) => ({
      original: imageChooserBlock.original_image.src,
      thumbnail: imageChooserBlock.renditions.thumbnail.src,
    }));
  }
}
