import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { LacertaFormBuilderService } from './form-builder.service';
import { LacertaFormControlComponent, LacertaFormMessages } from './form-control.component';
import { WagtailFormsApiService } from '@lacerta/wagtail';
import { LacertaFormField, LacertaFormSubmitStatus } from './form.model';
import { OnChange } from '@lacerta/util';
import { catchError, tap } from 'rxjs/operators';
import { BehaviorSubject, EMPTY } from 'rxjs';

@Component({
  selector: 'lacerta-form',
  template: `
    <form (ngSubmit)="onSubmit()" [formGroup]="form" *ngIf="form && fields && fields.length">
      <ng-container *ngFor="let field of fields">
        <ndc-dynamic
          [ndcDynamicComponent]="$any(lacertaFormControlComponent)"
          [ndcDynamicInputs]="{ field: field, form: form, submitStatus: submitStatus$ | async, formMessages: formMessages }"
        ></ndc-dynamic>
      </ng-container>
    </form>
  `,
})
export class LacertaFormComponent {
  @Input()
  @OnChange('buildFormGroup')
  fields: LacertaFormField<unknown>[] = [];

  @Input() target?: string;

  @Input() formMessages?: LacertaFormMessages | null;

  form?: FormGroup;

  submitStatus$ = new BehaviorSubject(LacertaFormSubmitStatus.NOT_SUBMITTED);

  constructor(
    private formBuilderService: LacertaFormBuilderService,
    private wagtailFormsApiService: WagtailFormsApiService,
    public lacertaFormControlComponent: LacertaFormControlComponent
  ) {}

  buildFormGroup(fields: LacertaFormField<unknown>[]) {
    if (fields && fields.length) {
      this.form = this.formBuilderService.buildFormGroup(this.fields);
    }
  }

  onSubmit() {
    if (this.form?.valid && this.target) {
      this.submitStatus$.next(LacertaFormSubmitStatus.SUBMITTED_VALID);
      this.wagtailFormsApiService
        .post(this.target, this.form.value)
        .pipe(
          tap(() => this.submitStatus$.next(LacertaFormSubmitStatus.SUBMIT_SUCCESS)),
          catchError(() => {
            this.submitStatus$.next(LacertaFormSubmitStatus.SUBMIT_ERROR);
            return EMPTY;
          })
        )
        .subscribe();
    } else {
      this.submitStatus$.next(LacertaFormSubmitStatus.SUBMITTED_INVALID);
    }
  }
}
