import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'backendLink',
})
export class BackendLinkPipe implements PipeTransform {
  static backendUrlRegex = /a href="http[s]?:\/\/[a-zA-Z0-9]*[.]wagtail[.]lacerta[.]be\/(.*?)"/g;

  transform(value: string): string {
    let result = value;
    let matches;
    while ((matches = BackendLinkPipe.backendUrlRegex.exec(value))) {
      result = result.replace(matches[0], `a href="/${matches[1]}"`);
    }
    return result;
  }
}
