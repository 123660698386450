import { Component, Input } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ProductGalleryImage, SetisProductPage } from '../../../core/setis-wagtail/product/product.model';
import { LacertaGalleryImage } from '@lacerta/ui';
import { LacertaCmsNgrxFacade } from '@lacerta/cms';

@Component({
  selector: 'setis-product-detail-page',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss'],
})
export class SetisProductDetailComponent {
  @Input() product?: SetisProductPage;

  currentDetailPage$: Observable<SetisProductPage> = this.lacertaCmsNgrxFacade.currentDetailPage$.pipe(
    filter((page) => !!page),
    map((page) => page as SetisProductPage)
  );
  galleryImages$: Observable<LacertaGalleryImage[]> = this.currentDetailPage$.pipe(
    filter((page) => !!page.gallery_images),
    map((page) =>
      page.gallery_images.map((productGalleryImage: ProductGalleryImage) => ({
        original: productGalleryImage.image.meta.download_url,
        thumbnail: page.gallery_images.length > 1 ? productGalleryImage.thumbnail.url : productGalleryImage.thumbnail_large.url,
      }))
    )
  );

  constructor(private lacertaCmsNgrxFacade: LacertaCmsNgrxFacade) {}
}
