import { Component, Input } from '@angular/core';
import type { WagtailTableBlock } from '@lacerta/wagtail';

@Component({
  selector: 'lacerta-table-block',
  templateUrl: './table-block-component.component.html',
})
export class TableBlockComponent {
  @Input() content?: WagtailTableBlock;

  get header() {
    return this.content?.first_row_is_table_header ? this.content.data[0] : [];
  }

  get rows() {
    return this.content?.first_row_is_table_header ? this.content.data.slice(1) : this.content?.data;
  }

  getRowHeader(row: string[]) {
    return this.content?.first_col_is_header ? row[0] : undefined;
  }

  getRowCols(row: string[]) {
    return this.content?.first_col_is_header ? row.slice(1) : row;
  }
}
