import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { WagtailConfig } from '../wagtail.config';

@Injectable({ providedIn: 'root' })
export class WagtailFormsApiService {
  constructor(private http: HttpClient, private wagtailConfig: WagtailConfig) {}

  post(slug: string, contactForm: { [key: string]: string }): Observable<unknown> {
    const formData = new FormData();
    Object.keys(contactForm).forEach((key) => formData.append(key, contactForm[key]));
    const headers = new HttpHeaders({ enctype: 'multipart/form-data' });
    return this.http.post(`${this.wagtailConfig.formsBaseUrl + slug}/`, formData, {
      headers,
    });
  }
}
