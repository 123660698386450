import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { WagtailStreamField } from '@lacerta/wagtail';
import { AttributesMap } from 'ng-dynamic-component';

@Component({
  selector: 'lacerta-stream-field',
  template: `
    <ng-container *ngFor="let block of blocks">
      <lacerta-dynamic-block [blockType]="block.type" [blockValue]="block.value" [attributes]="attrs(block)"></lacerta-dynamic-block>
    </ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LacertaStreamFieldComponent {
  @Input()
  blocks?: WagtailStreamField<unknown>[];

  attrs = (block: WagtailStreamField<unknown>): AttributesMap => ({
    class: `lacerta-dynamic-block`,
    'data-block-type': block.type,
  });
}
