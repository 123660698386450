import { LacertaImageChooserBlockImage } from '@lacerta/cms';

export class BlockUtil {
  public static mapImageWithTextBlockToFullWidthImage(imageChooserBlockImage: LacertaImageChooserBlockImage) {
    return {
      url: imageChooserBlockImage.src,
      width: imageChooserBlockImage.width,
      height: imageChooserBlockImage.height,
    };
  }
}
