import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export interface LacertaBulmaNavbarItem {
  text: string;
  targetSlug: string;
  exact: boolean;
  children: LacertaBulmaNavbarItem[];
}

@Component({
  selector: 'lacerta-bulma-navbar-items',
  templateUrl: './navbar-items.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarItemsComponent {
  @Input() bulmaNavbarItems?: LacertaBulmaNavbarItem[] | null;
  @Input() parentPath = '';
  @Input() dropdownArrowless?: boolean;
}
