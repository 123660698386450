import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'externalLinkBlank',
})
export class ExternalLinkBlankPipe implements PipeTransform {
  // TODO: add unit test
  transform(value: string): string {
    const regex = new RegExp(/href="(.*?)"/g);
    let result = value;
    let matches;
    while ((matches = regex.exec(value))) {
      const url = matches[1];
      const isSameRoot = url.startsWith(document.location.origin);
      const isDocumentLink = url.startsWith('/documents/');
      const isRelativeLink = url.startsWith('/');
      const isAnchorLink = url.startsWith('#');
      if (!isSameRoot && !isAnchorLink && (isDocumentLink || !isRelativeLink)) {
        result = result.replace(matches[0], `href='${matches[1]}' target='_blank'`);
      }
    }
    return result;
  }
}
