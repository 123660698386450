import { Component } from '@angular/core';
import { LacertaDynamicTypeComponent } from '@lacerta/cms';
import { BlockUtil } from './block-util';
import { VervitImageWithTextBlock } from '@lacerta/vervit/feature-shell';

@Component({
  template: `
    <lacerta-full-width-image [image]="image">
      <setis-title-transparent-block *ngIf="text">
        <lacerta-rich-text-block [content]="text | linkToButton: 'button'"></lacerta-rich-text-block>
      </setis-title-transparent-block>
    </lacerta-full-width-image>
  `,
})
export class SetisWagtailImageWithTextComponent extends LacertaDynamicTypeComponent<VervitImageWithTextBlock> {
  get image() {
    return this.context?.image.original_image
      ? BlockUtil.mapImageWithTextBlockToFullWidthImage(this.context?.image.original_image)
      : undefined;
  }

  get text() {
    return this.context?.text;
  }
}
