import { NgModule } from '@angular/core';
import { ContactComponent } from './contact.component';
import { LacertaBulmaModule } from '@lacerta/bulma';
import { SharedModule } from '../../shared/shared.module';
import { LacertaFormModule } from '@lacerta/form';

@NgModule({
  imports: [SharedModule, LacertaFormModule, LacertaBulmaModule],
  declarations: [ContactComponent],
  exports: [ContactComponent],
})
export class ContactModule {}
