<table class="table">
  <thead *ngIf="header">
    <tr>
      <th *ngFor="let col of header">{{ col }}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let row of rows">
      <th *ngIf="getRowHeader(row)">{{ getRowHeader(row) }}</th>
      <td *ngFor="let col of getRowCols(row)">{{ col }}</td>
    </tr>
  </tbody>
</table>
