import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'linkToButton',
})
export class LinkToButtonPipe implements PipeTransform {
  transform(value: string, args: string): string {
    const regex = new RegExp(/><a href="([^"]+)">([^<]+)<\/a></g);
    let result = value;
    let matches;
    while ((matches = regex.exec(value)) !== null) {
      result = result.replace(
        matches[0],
        `><a class='${args}' href='${matches[1]}' target='${matches[1].startsWith('/') ? '' : '_blank'}'>${matches[2]}</a><`
      );
    }
    return result;
  }
}
