import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GalleryImageComponent } from './gallery-image.component';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [GalleryImageComponent],
  imports: [CommonModule, FlexLayoutModule],
  exports: [GalleryImageComponent],
})
export class LacertaGalleryImageModule {}
