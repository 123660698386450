import { Component, Input } from '@angular/core';

@Component({
  selector: 'setis-two-columns-green',
  templateUrl: './two-columns-green.component.html',
  styleUrls: ['./two-columns-green.component.scss'],
})
export class TwoColumnsGreenComponent {
  @Input() leftContent?: string;
  @Input() rightContent?: string;
}
