import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { SetisAppComponent } from './app.component';
import { SetisCoreModule } from './core/core.module';
import { LacertaBulmaNavbarModule } from '@lacerta/bulma';

@NgModule({
  imports: [BrowserModule, SetisCoreModule, LacertaBulmaNavbarModule],
  declarations: [SetisAppComponent],
  bootstrap: [SetisAppComponent],
})
export class AppModule {}
