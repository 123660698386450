import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PagesResponse, WagtailPagesApiService } from '@lacerta/wagtail';
import { SetisProductField, SetisProductPage } from './product.model';
import { SetisPageType } from '../page/page.model';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  constructor(private pagesService: WagtailPagesApiService) {}

  getByParentId(parentId: number): Observable<SetisProductPage[]> {
    return this.pagesService
      .get<SetisProductPage>({
        type: SetisPageType.product,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        child_of: parentId,
        fields: [SetisProductField.code, SetisProductField.description, SetisProductField.price, SetisProductField.thumbnail],
      })
      .pipe(map((pagesResponse: PagesResponse<SetisProductPage>) => pagesResponse.items));
  }
}
