import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { LacertaFormControlType, LacertaFormField } from './form.model';

@Injectable({ providedIn: 'root' })
export class LacertaFormBuilderService {
  buildFormGroup(fields: LacertaFormField<unknown>[]): FormGroup {
    const group: {
      [key: string]: AbstractControl;
    } = {};

    fields.forEach((field: LacertaFormField<unknown>) => {
      const validators: ValidatorFn[] = [];
      if (field.required) {
        validators.push(Validators.required);
      }
      if (field.controlType === LacertaFormControlType.email) {
        validators.push(Validators.email);
      }
      if (field.key) {
        group[field.key] = new FormControl(field.value || '', validators);
      }
    });
    return new FormGroup(group);
  }
}
