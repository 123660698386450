import { Component, Input } from '@angular/core';

@Component({
  selector: 'setis-two-columns',
  styleUrls: ['two-columns.component.scss'],
  template: `<div class="container">
    <div class="columns is-centered has-text-link">
      <div class="column is-4">
        <div class="content left-content has-text-centered-mobile">
          <lacerta-rich-text-block *ngIf="leftContent" [content]="leftContent"></lacerta-rich-text-block>
        </div>
      </div>
      <div class="column is-8 has-vertically-aligned-content">
        <div class="content right-content has-text-centered-mobile">
          <lacerta-rich-text-block
            *ngIf="rightContent"
            [content]="rightContent | linkToButton: 'button is-link is-size-6'"
          ></lacerta-rich-text-block>
        </div>
      </div>
    </div>
  </div>`,
})
export class TwoColumnsComponent {
  @Input() leftContent?: string;
  @Input() rightContent?: string;
}
