<div *ngIf="form" [formGroup]="form" class="field">
  <label *ngIf="field?.label && field?.placeholder" class="label">{{ field?.label }}</label>
  <div class="control" [ngSwitch]="field?.controlType">
    <input
      *ngSwitchCase="lacertaFormControlType.email"
      type="email"
      class="input"
      [formControlName]="field?.key || null"
      [placeholder]="field?.placeholder || field?.label"
      [attr.disabled]="isDisabled ? '' : null"
    />
    <ng-container *ngSwitchCase="lacertaFormControlType.checkbox">
      <label class="checkbox">
        <input type="checkbox" [formControlName]="field?.key || null" [attr.disabled]="isDisabled ? '' : null" />
        {{ field?.label }}
      </label>
    </ng-container>
    <textarea
      *ngSwitchCase="lacertaFormControlType.textarea"
      class="textarea"
      [formControlName]="field?.key || null"
      [placeholder]="field?.placeholder || field?.label"
      [attr.disabled]="isDisabled ? '' : null"
    ></textarea>
    <ngx-recaptcha2
      *ngSwitchCase="lacertaFormControlType.captcha"
      #captchaElem
      [siteKey]="'6LevP_ASAAAAAB280JpDt4gsappg_EXl0wBXNmrV'"
      formControlName="g-recaptcha-response"
      [attr.disabled]="isDisabled ? '' : null"
    ></ngx-recaptcha2>
    <button
      *ngSwitchCase="lacertaFormControlType.submit"
      type="submit"
      class="button"
      [ngClass]="field?.style || ''"
      [class.is-loading]="isLoading"
      [disabled]="isDisabled"
    >
      {{ field?.value }}
    </button>
    <input
      *ngSwitchCase="lacertaFormControlType.number"
      type="number"
      class="input"
      [formControlName]="field?.key || null"
      [placeholder]="field?.placeholder || field?.label"
      [attr.disabled]="isDisabled ? '' : null"
    />
    <ng-container *ngSwitchDefault>
      <input
        *ngIf="field?.key as fieldKey"
        type="text"
        class="input"
        [formControlName]="fieldKey"
        [placeholder]="field?.placeholder || field?.label"
        [attr.disabled]="isDisabled ? '' : null"
      />
    </ng-container>
  </div>
  <p class="help" [ngClass]="{ 'is-danger': isInvalid }">{{ errorMessage }}</p>
  <ng-container *ngIf="field?.controlType === lacertaFormControlType.submit">
    <ng-container [ngSwitch]="submitStatus">
      <div *ngSwitchCase="lacertaFormSubmitStatus.SUBMITTED_INVALID" class="help is-danger">
        {{ formMessagesLookup?.submitStatus?.submittedInvalid || 'Please check if all fields are filled out correctly and try again.' }}
      </div>
      <div *ngSwitchCase="lacertaFormSubmitStatus.SUBMITTED_VALID" class="notification is-info">
        {{ formMessagesLookup?.submitStatus?.submittedValid || 'Your message is being sent.' }}
      </div>
      <div *ngSwitchCase="lacertaFormSubmitStatus.SUBMIT_SUCCESS" class="notification is-success">
        {{ formMessagesLookup?.submitStatus?.submitSuccess || 'Your message has been succesfully sent.' }}
      </div>
      <div *ngSwitchCase="lacertaFormSubmitStatus.SUBMIT_ERROR" class="notification is-danger">
        {{ formMessagesLookup?.submitStatus?.submitError || 'Something went wrong. If the issue persists, please contact us by email.' }}
      </div>
    </ng-container>
  </ng-container>
</div>
